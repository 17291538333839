import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/main.css"

const Main: React.SFC<void> = () => (
  <Layout>
    <SEO
      title="Home"
      description="Manuel Cepeda personal site"
      lang="en"
      meta={[]}
    />
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '65vh',
      justifyContent: 'center',
    }}>
      <p>
        Hello world! My name is Manuel Cepeda a {new Date().getFullYear() - 1993} years old
        Software Developer from the&nbsp;
        <a href="https://en.wikipedia.org/wiki/Dominican_Republic" target="_blank" rel="noopener noreferrer">Dominican Republic</a>.
      </p>
      <h3>
        This page is currently a WIP, so please stay tune.
      </h3>
      <p>You can reach out to me at:</p>
      <ul>
        <li>
          <a href="https://www.linkedin.com/in/manuel-cepeda-0336a999/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </li>
        <li>
          <a href="https://twitter.com/mecm1993" target="_blank" rel="noopener noreferrer">@mecm1993 on Twitter</a>
        </li>
        <li>
          <a href="https://github.com/mecm1993" target="_blank" rel="noopener noreferrer">mecm1993 on GitHub</a>
        </li>
        <li>
          <a href="https://www.instagram.com/mecm93/" target="_blank" rel="noopener noreferrer">mecm93 on Instagram</a>
        </li>
        <li>
          <a href="mailto:iam@manuelcepeda.dev">iam@manuelcepeda.dev</a>
        </li>
      </ul>
    </div>
  </Layout>
)

export default Main
